var site = window.site || {};
var generic = window.generic || {};

(function ($) {
  'use strict';

  // Use standard Drupal behaviors to initialize things.
  Drupal.behaviors.langInterstitial = {
    attach: function (context) {
      var self = this;

      if (self.attached || !Drupal.settings.enable_interstitial) {
        return;
      }
      self.attached = true;

      self.init();
      $('body', context).addClass('locate-interstitial-popup');
    },

    conf : {
      popoverDisplayModalMask: 1,
      displayPoImmediately: 1,
      homepage: 1,
      langPref: 48,
      popoverFilename: '/promotions/includes/popovers/interstitial.tmpl',
      callbackName: 'undefined',
      popUpCountCookie: 'ca_lang_select_count',
      setPopoverCookieOnly: 0,
      cookieDuration: '30'
    },

    initConf: function () {
      var self = this;

      if (self.conf.popoverFilename) {
        self.process();
      }
    },

    init: function () {
      var self = this;

      try {
        self.initConf();
      } catch (err) {
      }
    },

    process: function () {
      var self = this;
      var conf = self.conf;
      var url = conf.popoverFilename;
      var includeBackgroundFlag = conf.popoverDisplayModalMask;
      var popupDiv = '';
      var timeToWait = conf.displayPoImmediately ? 0 : 2500;
      var callback = conf.callbackName;

      if (generic.cookie(conf.popUpCountCookie)) {
        return;
      }

      $.ajax(url, {
        dataType: 'html',
        success: function (html) {
          if (!(/\S/).test(html)) {
            self.setCookie();

            return;
          }

          popupDiv = $('<div id="popover_container"></div>').html(html).get(0);

          window.setTimeout(function () {
            generic.overlay.launch({
              content: popupDiv,
              includeBackground: includeBackgroundFlag,
              fixed: 'true',
              appearDuration: 1.0,
              cssStyle: {
                overflow: 'visible ',
                border: 'none',
                backgroundColor: '#000',
                width: '550px',
                height: '400px',
                position: 'fixed',
                className: 'overlay--interstitial',
                trapFocus: false,
                onComplete: function () {
                  $('#cboxClose').on('click', function () {
                    site.caCookieLegal.process();
                  });
                }
              }
            });

            if (typeof callback === 'function') {
              callback();
            }

            self.setCookie();
          }, timeToWait);
        }
      });
    },

    setCookie: function () {
      var self = this;
      var conf = self.conf;
      var expDate = new Date();
      var expDateString = new Date();

      expDate.setDate(expDate.getDate() + conf.cookieDuration);
      expDateString = expDate.toGMTString();

      generic.cookie(conf.popUpCountCookie, '1', {
        path: '/',
        expires: expDateString
      });
    },

    closeSitewidePopover: function () {
      generic.overlay.hide();
    },

    // Need this width for centering popup.
    getLargestWidth: function (poContainer) {
      var $blocks = $('div', poContainer);
      var largestWidth = 0;

      $blocks.each(function () {
        var nWidth = $(this).css('width');

        if (nWidth !== null) {
          nWidth = Number(nWidth.sub('px', ''));
          largestWidth = (nWidth > largestWidth) ? nWidth : largestWidth;
        }
      });

      return largestWidth;
    },

    getLeftPosition: function (poContainer) {
      var self = this;
      var width = self.getLargestWidth(poContainer);
      var windowWidth = $.browser.msie ? document.documentElement.clientWidth : window.innerWidth;

      return (windowWidth / 2) - (width / 2);
    },

    attached: false
  };
})(jQuery);
